/* stylelint-disable media-feature-name-no-vendor-prefix */
$black: #000;
$white: #fff;

$body-bg: $black;
$body-color: $white;
$secondary-bg: #111;
$tab-bg: $secondary-bg;
$secondary-bg-border: #222;
$secondary-bg-warning: #ffc700;
$key-line: #bebebe;
$off-white: #bebebe; // dark-grey
$red: #ec002b;
$platinum: #4fafcd;
$gold: #d4b300;
$silver: #8b8b8b;
$bronze: #b1681f;
$xumm-blue: #061d78;

$box-bg: #111;
$box-border: #222;
$text-input-border: #444;

$primary: #03bfb5;
$primary-dark: #16948c;

@import '../node_modules/bootstrap/scss/bootstrap';

$error: $red;

$tiny-font: 12px;
$font-size: 16px;
$font-light: 200;
$font-normal: 300;
$font-medium: 400;
$font-semi: 500;
$font-bold: 600;

html,
body {
  font-family: poppins, sans-serif;
  font-size: $font-size;
  font-style: normal;
  font-weight: $font-normal;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: 100vh;
  padding: 0;
}

// Default style for links
a {
  color: $white;
  text-decoration: none;

  &:hover {
    color: $primary;
  }
}

// overrides
:focus-visible {
  color: inherit !important;
  outline: 0 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background: #000;
  background-color: $black !important;
  -webkit-box-shadow: 0 0 0 1000px #000 inset;
  color: $white !important;
  -webkit-text-fill-color: $white !important;
  transition: background-color 5000s ease-in-out 0s;
}

.form-check {
  .form-check-input {
    border: 1px solid $white !important;
  }

  label {
    line-height: 1.75;
  }
}

.app {
  flex: 1;
  padding: 0;

  .container {
    padding: 0;

    > .row {
      margin: 0;

      > * {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }

  .main-content {
    padding: 16px;
    @include media-breakpoint-up(sm) {
      padding: initial;
    }
  }
}

.navbar {
  background-color: $body-bg;
  font-size: 20px;
  font-weight: $font-medium;
  margin: 0;
  padding: 18px 0;
  @include media-breakpoint-up(sm) {
    padding: 18px 0;
  }
  @include media-breakpoint-up(lg) {
    padding: 32px 0;
  }

  .container {
    padding: 0 16px;
  }

  .navbar-brand {
    margin: 0;
    padding: 0;

    img {
      height: 48px;
      @include media-breakpoint-up(sm) {
        height: 64px;
      }
    }
  }

  .navbar-toggler {
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
      height: 80%;
      width: 80%;
    }

    &.circle {
      background-color: transparent;
      border: 1px solid $white;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      height: 32px;
      padding: 0 1px 0 2px;
      width: 32px;
      @include media-breakpoint-up(sm) {
        border: 2px solid $white;
        height: 42px;
        padding: 0 0 0 1px;
        width: 42px;
      }
    }
  }

  .navbar-nav {
    align-items: center;
  }

  .nav-link {
    color: $white;
    letter-spacing: 0;
    line-height: 1;
    margin-right: 16px;
    padding: 16px;
    text-shadow: 3px 2px $black;

    &.active {
      color: $primary;
      font-weight: $font-semi;

      &:hover {
        color: lighten($primary, 15%);
      }
    }

    &:hover {
      color: $primary;
    }

    &:last-child {
      margin-right: 0;
      padding-right: 0;
    }
  }
}

.offcanvas-backdrop {
  &.show {
    background-color: #333;
    opacity: 0.9;
  }
}

.offcanvas {
  background-color: $body-bg;
  font-size: 20px;

  .offcanvas-title {
    img {
      height: 48px;
      @include media-breakpoint-up(sm) {
        height: 64px;
      }
    }
  }

  .nav-link {
    color: $white;
    letter-spacing: 0;
    line-height: 1;
    padding: 16px;
    text-align: center;

    &:hover {
      color: $primary;
    }
  }

  .sign-out-container {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    padding: 0;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }

    &:hover {
      color: $white !important;
    }
  }

  .sign-out {
    border: 1px solid $white;
    border-radius: 8px;
    display: inline;
    padding: 12px;

    &:hover {
      background-color: $primary;
      border-color: $primary;
      color: $white !important;
      text-shadow: none;
    }
  }
}

footer {
  border-top: 1px solid $secondary-bg-border;
  padding: 0;

  .container {
    padding: 0;
  }

  .row {
    margin: 0;
  }

  .bpm-logo {
    img {
      height: 64px;
      margin: 32px 0;
    }
  }

  .social-icons {
    img {
      height: 32px;
      margin: 32px 16px;
      width: 32px;

      @include media-breakpoint-up(md) {
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .footer-links {
    align-self: center;

    @include media-breakpoint-up(md) {
      flex-grow: 0;
    }

    a {
      color: $white;
      font-size: $tiny-font;
      margin-right: 8px;
      text-decoration: none;

      &::after {
        content: '|';
        margin-left: 8px;
      }

      &:last-child {
        margin-right: 0;

        &::after {
          content: '';
          margin-left: 0;
        }
      }

      &:hover {
        color: $primary;
        text-decoration: underline;
      }
    }
  }

  .copyright-notice {
    flex-direction: column;
    flex-grow: 1;
    font-size: $tiny-font;

    @include media-breakpoint-up(md) {
      flex-direction: row;

      div {
        &:first-child {
          margin-right: 12px;
        }
      }
    }

    div {
      align-self: center;
    }
  }
}

// Modals
.modal-backdrop {
  &.show {
    background-color: $white;
    opacity: 0.25;
  }
}

.modal {
  .modal-content {
    background-color: $black;
    border: 1px solid $box-border;
    border-radius: 8px;

    @include media-breakpoint-up(md) {
      margin: 180px auto 0;
    }
  }

  .modal-header {
    border: 0;
    @include media-breakpoint-up(md) {
      padding-bottom: 0;
    }
  }

  .modal-body {
    .modal-icon {
      padding: 0 0 24px;

      img {
        height: 24px;
      }
    }

    .modal-title {
      font-size: 32px;
      font-weight: $font-bold;
      margin: 0 0 16px;
      text-align: center;
    }

    .modal-instructions {
      color: $white;
      font-size: 16px;
      margin: 0 0 24px;
      text-align: center;
    }

    .modal-primary-cta {
      align-self: center;
      width: 100%;

      @include media-breakpoint-up(lg) {
        width: auto;
      }
    }

    .error-message {
      border: 1px solid $error;
      border-radius: 8px;
      color: $white;
      display: flex;
      font-size: 16px;
      justify-content: center;
      padding: 16px;

      img {
        height: 32px;
      }
    }
  }
}

.xumm-modal {
  .modal-title {
    margin: 16px 0 !important;
  }

  .modal-instructions {
    .ok {
      margin: 0 auto;
      width: 80%;
    }

    .not-mobile {
      margin: 16px auto !important;
      width: 80%;
    }

    .fail {
      color: $error;
    }

    .change-mobile {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .xumm-mob {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;

    .xumm-button {
      align-items: center;
      background-color: $xumm-blue;
      border-radius: 10px;
      color: $white;
      display: flex;
      justify-content: center;
      padding: 16px 32px;
      margin-bottom: 24px;
    }
  }

  .xumm-qr {
    display: flex;
    height: 292px;
    justify-content: center;
    position: relative;

    .xumm-qr-expired {
      //background-color: $black;
      color: $error;
      font-size: 68px;
      font-weight: 800;
      line-height: 1;
      padding: 16px;
      position: absolute;
      text-align: center;
      text-shadow: 4px 4px #000;
      top: 100px;
    }
  }

  .expires {
    color: $off-white;
    height: 28px;
    margin: 0;
    padding: 4px 0 0;
    text-align: center;

    p {
      margin: 0;
    }
  }

  .status {
    border-radius: 8px;
    font-size: 20px;
    margin: 32px auto;
    padding: 16px;
    text-align: center;
    width: 80%;

    &.ok {
      border: 2px solid $primary;
      color: $primary;
    }

    &.fail {
      border: 2px solid $error;
      color: $error;
    }

    p {
      margin: 0;
    }
  }

  .mobile-warning {
    font-size: 14px;
    margin-top: 32px;
    text-align: center;
  }

  .xumm-loading {
    .xumm-qr {
      align-items: center;
      display: flex;
      height: 450px;
      justify-content: center;
      @include media-breakpoint-up(lg) {
        height: 405px;
      }
    }

    .status {
      margin: 32px auto;
      padding: 16px;
    }

    .spinner {
      height: 64px;
      width: 64px;

      &::after {
        height: 64px;
        width: 64px;
      }
    }
  }

  .modal-footer {
    border-color: #222;
    justify-content: center;

    .download-xumm {
      font-size: 12px;
      text-align: center;
    }

    .concern-statement {
      font-size: 12px;
      text-align: center;

      a {
        color: $primary;
        margin: 0 6px;
      }
    }

    .apple-logo {
      display: flex;
      justify-content: flex-end;
    }

    .android-logo {
      display: flex;
      justify-content: flex-start;
    }

    img {
      height: 36px;

      @include media-breakpoint-up(md) {
        height: 48px;
      }
    }
  }
}

.persist-sign-in {
  font-size: 16px;
  font-weight: $font-medium;

  label {
    line-height: 1;
    margin: 0 0 0 12px;
  }
}

.sign-in-modal {
  @include media-breakpoint-up(md) {
    padding: 0 16px 32px;
  }
}

.register-modal {
  @include media-breakpoint-up(md) {
    padding: 0 0 32px;
  }

  .icon {
    margin-bottom: 16px;
    text-align: center;
  }
}

.modify-modal {
  text-align: center;

  .modal-body {
    padding: 16px;
  }

  .icon {
    margin-bottom: 16px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 16px;
    }

    img {
      height: 40px;
    }
  }

  .title {
    font-weight: $font-bold;
  }

  .slider-explanation {
    font-size: 16px;
    font-weight: $font-normal;
    margin-top: 16px;
  }

  .how-much-bpm {
    margin-bottom: 48px;
  }

  .how-many-months {
    margin-bottom: 16px;
  }

  .modal-footer {
    border-color: $box-border;
  }
}

.confirm-modal {
  .modal-body {
    padding: 32px 16px;
  }

  .modal-footer {
    border-color: $box-border;
  }
}

.alias-modal {
  .input {
    background-color: transparent;
    border: 1px solid $text-input-border;
    color: $white;
    padding: 8px;
    width: 100%;
  }

  .modal-body {
    padding: 32px 16px;
  }

  .modal-footer {
    border-color: $box-border;
  }
}

.delete-account-modal {
  .input {
    background-color: transparent;
    border: 1px solid $text-input-border;
    color: $white;
    padding: 8px;
    width: 100%;
  }

  .modal-body {
    padding: 32px 16px;
  }

  .modal-footer {
    border-color: $box-border;
  }
}

.rewards-plan-modal {
  .modal-dialog {
    @include media-breakpoint-up(md) {
      max-width: 735px;
    }
  }

  .modal-body {
    padding: 0 0 32px;

    .modal-title {
      font-size: 32px;
    }

    .modal-instructions {
      margin-bottom: 32px;
    }

    .platinum-text {
      color: $platinum;
    }

    .gold-text {
      color: $gold;
    }

    .silver-text {
      color: $silver;
    }

    .bronze-text {
      color: $bronze;
    }

    .bpm-only-text {
      color: $primary;
    }

    .perc {
      font-weight: $font-bold;
    }
  }

  .rewards-grid {
    .col {
      text-align: center;
    }

    .icon {
      border-top: 1px solid $box-border;
      padding: 8px 0 0;
    }

    .name {
      font-size: 20px;
      font-weight: $font-bold;
      padding: 0 0 16px;
    }

    .tier {
      color: #ccc;
      padding-bottom: 16px;
    }

    .period {
      color: #ccc;
      padding-bottom: 16px;
    }

    .perc {
      margin-bottom: 16px;

      .col {
        padding-bottom: 16px;
      }
    }
  }
}

.modal-links {
  font-size: 16px;
  margin-top: 32px;
  text-align: center;

  > div {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .link {
    color: $primary;
    cursor: pointer;
    font-weight: $font-semi;
  }
}

// Utility
.text-white-outline {
  color: transparent;
  font-weight: 800;
  -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
  -webkit-text-stroke-color: $white;
  -webkit-text-stroke-width: 1px;
}

.text-white-bold {
  color: $white;
  font-weight: 600;
}

.primary-link {
  color: $primary;
}

// Input styling
.form-field-text {
  border: 1px solid $white;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
}

.invalid-input {
  border-color: $white;

  .input-label {
    color: $white;
  }
}

.input-container {
  display: flex;
  flex-grow: 1;
  position: relative;
}

.icon-container {
  align-items: center;
  display: flex;
  flex-grow: 0;
  margin: 0 16px 0 0;

  img {
    height: 20px;
  }
}

.input-label {
  background: $secondary-bg;
  color: $white;
  font-size: $tiny-font;
  font-weight: 300;
  left: 12px;
  padding: 0 8px;
  position: absolute;
  top: -8px;
}

.text-input {
  background: transparent;
  border: 0;
  color: $white;
  font-size: 16px;
  font-weight: 300;
  line-height: 40px;
  margin: 0 8px;
  padding: 12px;
  width: 100%;
}

.textarea-input {
  background-color: transparent;
  border: 0;
  color: $white;
  font-size: 16px;
  font-weight: 300;
  line-height: 40px;
  padding: 12px 20px;
  width: 100%;
}

.input-select {
  background-color: transparent;
  border: 0;
  color: $white;
  font-size: 16px;
  font-weight: 300;
  line-height: 40px;
  padding: 12px 20px;
  width: 100%;
}

.input-error {
  color: $error;
  font-size: 16px;
  margin-top: 8px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// Loading Screen
.loading-container {
  align-items: center;
  display: flex;
  height: 50vh;
  justify-content: center;
}

// Tabs
.nav-tabs {
  border: 0;

  .nav-item {
    border-radius: 0 !important;

    .nav-link {
      border: 0;
      border-radius: 0 !important;
      padding: 24px 0 16px;

      .icon {
        height: 32px;
        margin: 0 auto;
      }

      &.active {
        background-color: $tab-bg;
        height: 100%;
      }

      .icon-user {
        background-image: url('data:image/svg+xml;utf8,<svg width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.0009 20C25.1293 20 29.2866 15.8426 29.2866 10.7142C29.2866 5.58588 25.1293 1.42853 20.0009 1.42853C14.8725 1.42853 10.7152 5.58588 10.7152 10.7142C10.7152 15.8426 14.8725 20 20.0009 20Z" stroke="white" stroke-width="2.85714" stroke-linecap="round" stroke-linejoin="round"/><path d="M37.658 38.5714C36.4585 34.831 34.1022 31.5681 30.9288 29.2532C27.7554 26.9383 23.9289 25.6909 20.0009 25.6909C16.0729 25.6909 12.2463 26.9383 9.07294 29.2532C5.89954 31.5681 3.54324 34.831 2.34375 38.5714H37.658Z" stroke="white" stroke-width="2.85714" stroke-linecap="round" stroke-linejoin="round"/></svg>');
        width: 32px;
      }

      .icon-email {
        background-image: url('data:image/svg+xml;utf8,<svg width="38" height="32" viewBox="0 0 40 34" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.42896 5.6143L18.1575 19.7314C18.673 20.1665 19.3258 20.4052 20.0004 20.4052C20.6749 20.4052 21.3278 20.1665 21.8432 19.7314L38.5718 5.6143M1.42896 2H38.5718V32H1.42896V2Z" stroke="white" stroke-width="2.85714" stroke-linecap="round" stroke-linejoin="round"/></svg>');
        width: 38px;
      }

      .icon-password {
        background-image: url('data:image/svg+xml;utf8,<svg width="28" height="32" viewBox="0 0 32 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23.056 4.37136C22.136 3.43423 21.0374 2.69099 19.8253 2.18554C18.6132 1.68008 17.3121 1.42267 15.9988 1.4285C13.3467 1.4285 10.8031 2.48207 8.92778 4.35743C7.05242 6.2328 5.99885 8.77634 5.99885 11.4285V15.7142M4.57028 15.7142H27.4274C29.0054 15.7142 30.2846 16.9934 30.2846 18.5714V35.7142C30.2846 37.2922 29.0054 38.5714 27.4274 38.5714H4.57028C2.99232 38.5714 1.71313 37.2922 1.71313 35.7142V18.5714C1.71313 16.9934 2.99232 15.7142 4.57028 15.7142ZM17.4274 27.1428C17.4274 27.9318 16.7878 28.5714 15.9988 28.5714C15.2099 28.5714 14.5703 27.9318 14.5703 27.1428C14.5703 26.3538 15.2099 25.7142 15.9988 25.7142C16.7878 25.7142 17.4274 26.3538 17.4274 27.1428Z" stroke="white" stroke-width="2.85714" stroke-linecap="round" stroke-linejoin="round"/></svg>');
        width: 28px;
      }

      &.completed {
        .icon-user {
          background-image: url('data:image/svg+xml;utf8,<svg width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.0009 20C25.1293 20 29.2866 15.8426 29.2866 10.7142C29.2866 5.58588 25.1293 1.42853 20.0009 1.42853C14.8725 1.42853 10.7152 5.58588 10.7152 10.7142C10.7152 15.8426 14.8725 20 20.0009 20Z" stroke="%2303bfb5" stroke-width="2.85714" stroke-linecap="round" stroke-linejoin="round"/><path d="M37.658 38.5714C36.4585 34.831 34.1022 31.5681 30.9288 29.2532C27.7554 26.9383 23.9289 25.6909 20.0009 25.6909C16.0729 25.6909 12.2463 26.9383 9.07294 29.2532C5.89954 31.5681 3.54324 34.831 2.34375 38.5714H37.658Z" stroke="%2303bfb5" stroke-width="2.85714" stroke-linecap="round" stroke-linejoin="round"/></svg>');
        }

        .icon-email {
          background-image: url('data:image/svg+xml;utf8,<svg width="38" height="32" viewBox="0 0 40 34" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.42896 5.6143L18.1575 19.7314C18.673 20.1665 19.3258 20.4052 20.0004 20.4052C20.6749 20.4052 21.3278 20.1665 21.8432 19.7314L38.5718 5.6143M1.42896 2H38.5718V32H1.42896V2Z" stroke="%2303bfb5" stroke-width="2.85714" stroke-linecap="round" stroke-linejoin="round"/></svg>');
        }

        .icon-password {
          background-image: url('data:image/svg+xml;utf8,<svg width="28" height="32" viewBox="0 0 32 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23.056 4.37136C22.136 3.43423 21.0374 2.69099 19.8253 2.18554C18.6132 1.68008 17.3121 1.42267 15.9988 1.4285C13.3467 1.4285 10.8031 2.48207 8.92778 4.35743C7.05242 6.2328 5.99885 8.77634 5.99885 11.4285V15.7142M4.57028 15.7142H27.4274C29.0054 15.7142 30.2846 16.9934 30.2846 18.5714V35.7142C30.2846 37.2922 29.0054 38.5714 27.4274 38.5714H4.57028C2.99232 38.5714 1.71313 37.2922 1.71313 35.7142V18.5714C1.71313 16.9934 2.99232 15.7142 4.57028 15.7142ZM17.4274 27.1428C17.4274 27.9318 16.7878 28.5714 15.9988 28.5714C15.2099 28.5714 14.5703 27.9318 14.5703 27.1428C14.5703 26.3538 15.2099 25.7142 15.9988 25.7142C16.7878 25.7142 17.4274 26.3538 17.4274 27.1428Z" stroke="%2303bfb5" stroke-width="2.85714" stroke-linecap="round" stroke-linejoin="round"/></svg>');
        }
      }
    }
  }
}

.tab-content {
  background-color: $tab-bg;
  padding: 32px 16px;
  @include media-breakpoint-up(sm) {
    padding: 48px;
  }

  .input-label {
    background-color: $tab-bg;
  }
}

// Buttons
.btn {
  --bs-btn-focus-box-shadow: 0 !important;
  border-radius: 8px;
  font-size: 16px;
  font-weight: $font-semi;

  padding: 18px 32px;

  &.btn-sm {
    padding: 6px;
  }

  &:disabled {
    opacity: 0.3;
  }
}

.btn-sm {
  min-width: unset !important;
  padding: 8px 16px !important;
}

.btn-danger {
  background: $red;
  border: 2px solid $red;
  border-radius: 8px;
}

.btn-primary {
  background: linear-gradient(92.21deg, $primary 0%, $primary-dark 100%);
  border: 2px solid $primary;
  border-radius: 8px;
  color: $white;
  min-width: 288px; //TODO: review this

  &:active {
    border: 2px solid $primary-dark;
    color: $white !important;
  }

  &:hover {
    background: linear-gradient(92.21deg, $primary 0%, darken($primary-dark, 5%) 100%);
    border: 2px solid $primary;
    color: $white !important;
  }

  &:disabled {
    background: none;
    background-color: #d2d2d2;
    border: 2px solid #d2d2d2;
    color: darken($white, 50%) !important;
    cursor: not-allowed;
  }
}

.btn-outline-primary {
  background: rgba(0, 0, 0, 0.25);
  border: 2px solid $white;
  border-radius: 8px;
  color: $white;
  min-width: 288px; //TODO: review this

  &:focus-visible {
    background: rgba(0, 0, 0, 0.25);
    border: 2px solid $white;
    box-shadow: none;
    color: $white;
  }

  &:first-child {
    &:active {
      background: rgba(0, 0, 0, 0.25);
      border: 2px solid $white;
      color: $white;
    }
  }

  &:hover {
    background: rgba(0, 0, 0, 0.5);
    border: 2px solid $white;
    color: $white;
  }
}

.btn-close {
  background: transparent
    url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FFF%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e')
    center/0.6em auto no-repeat;
  border: 1px solid $white !important;
  border-radius: 50%;
  color: $white;
  height: 10px;
  margin: 0 0 0 auto !important;
  min-width: unset !important;
  opacity: 1;
  padding: 10px !important;
  width: 10px;

  @include media-breakpoint-up(sm) {
    padding: 11px;
  }

  &:hover {
    background: #fff
      url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e')
      center/0.6em auto no-repeat;
    border: 1px solid $white !important;
  }
}

// Spinners
.spinner {
  display: inline-block;
  height: 16px;
  width: 16px;

  &::after {
    animation: spinner 1.2s linear infinite;
    border: 6px solid $primary;
    border-color: #333 $primary;
    border-radius: 50%;
    content: ' ';
    display: block;
    height: 16px;
    width: 16px;
  }
}

.btn-spinner {
  height: 16px;
  margin-left: 8px;
  padding-top: 2px;
  width: 16px;

  &::after {
    border: 6px solid #fff;
    border-color: #fff transparent;
    height: 16px;
    width: 16px;
  }
}

.input-spinner {
  height: 16px;
  width: 16px;

  &::after {
    border: 6px solid $primary;
    border-color: #333 $primary;
    height: 16px;
    width: 16px;
  }
}

.page-spinner {
  height: 64px;
  width: 64px;

  &::after {
    border: 6px solid $primary;
    border-color: #333 $primary;
    height: 64px;
    width: 64px;
  }
}

.early-bird {
  padding-top: 100px;
  text-align: center;

  .spinner {
    height: 64px;
    width: 64px;

    &::after {
      border: 6px solid $primary;
      border-color: #333 $primary;
      height: 64px;
      width: 64px;
    }
  }
}

// Pages
.hero-container {
  align-items: center;
  align-self: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;

  &.home-hero-container {
    background-image: url('../public/images/hero/home-xs.jpg');
    @include media-breakpoint-only(sm) {
      background-image: url('../public/images/hero/home-sm.jpg');
    }
    @include media-breakpoint-only(md) {
      background-image: url('../public/images/hero/home-md.jpg');
    }
    @include media-breakpoint-only(lg) {
      background-image: url('../public/images/hero/home-lg.jpg');
    }
    @include media-breakpoint-only(xl) {
      background-image: url('../public/images/hero/home-xl.jpg');
    }
    @include media-breakpoint-only(xxl) {
      background-image: url('../public/images/hero/home-xxl.jpg');
    }

    @media only screen and (-webkit-min-device-pixel-ratio: 2),
      only screen and (min--moz-device-pixel-ratio: 2),
      only screen and (-o-min-device-pixel-ratio: 2/1),
      only screen and (min-device-pixel-ratio: 2),
      only screen and (min-resolution: 192dpi),
      only screen and (min-resolution: 2dppx) {
      background-image: url('../public/images/hero/home-xsx2.jpg');
      @include media-breakpoint-only(sm) {
        background-image: url('../public/images/hero/home-smx2.jpg');
      }
      @include media-breakpoint-only(md) {
        background-image: url('../public/images/hero/home-mdx2.jpg');
      }
      @include media-breakpoint-only(lg) {
        background-image: url('../public/images/hero/home-lgx2.jpg');
      }
      @include media-breakpoint-only(xl) {
        background-image: url('../public/images/hero/home-xlx2.jpg');
      }
      @include media-breakpoint-only(xxl) {
        background-image: url('../public/images/hero/home-xxlx2.jpg');
      }
    }
  }

  &.earlybird-hero-container {
    background-image: url('../public/images/hero/earlybird-xs.jpg');
    @include media-breakpoint-only(sm) {
      background-image: url('../public/images/hero/earlybird-sm.jpg');
    }
    @include media-breakpoint-only(md) {
      background-image: url('../public/images/hero/earlybird-md.jpg');
    }
    @include media-breakpoint-only(lg) {
      background-image: url('../public/images/hero/earlybird-lg.jpg');
    }
    @include media-breakpoint-only(xl) {
      background-image: url('../public/images/hero/earlybird-xl.jpg');
    }
    @include media-breakpoint-only(xxl) {
      background-image: url('../public/images/hero/earlybird-xxl.jpg');
    }

    @media only screen and (-webkit-min-device-pixel-ratio: 2),
      only screen and (min--moz-device-pixel-ratio: 2),
      only screen and (-o-min-device-pixel-ratio: 2/1),
      only screen and (min-device-pixel-ratio: 2),
      only screen and (min-resolution: 192dpi),
      only screen and (min-resolution: 2dppx) {
      background-image: url('../public/images/hero/earlybird-xsx2.jpg');
      @include media-breakpoint-only(sm) {
        background-image: url('../public/images/hero/earlybird-smx2.jpg');
      }
      @include media-breakpoint-only(md) {
        background-image: url('../public/images/hero/earlybird-mdx2.jpg');
      }
      @include media-breakpoint-only(lg) {
        background-image: url('../public/images/hero/earlybird-lgx2.jpg');
      }
      @include media-breakpoint-only(xl) {
        background-image: url('../public/images/hero/earlybird-xlx2.jpg');
      }
      @include media-breakpoint-only(xxl) {
        background-image: url('../public/images/hero/earlybird-xxlx2.jpg');
      }
    }
  }

  &.rewards-hero-container {
    background-image: url('../public/images/hero/rewards-xs.jpg');
    @include media-breakpoint-only(sm) {
      background-image: url('../public/images/hero/rewards-sm.jpg');
    }
    @include media-breakpoint-only(md) {
      background-image: url('../public/images/hero/rewards-md.jpg');
    }
    @include media-breakpoint-only(lg) {
      background-image: url('../public/images/hero/rewards-lg.jpg');
    }
    @include media-breakpoint-only(xl) {
      background-image: url('../public/images/hero/rewards-xl.jpg');
    }
    @include media-breakpoint-only(xxl) {
      background-image: url('../public/images/hero/rewards-xxl.jpg');
    }

    @media only screen and (-webkit-min-device-pixel-ratio: 2),
      only screen and (min--moz-device-pixel-ratio: 2),
      only screen and (-o-min-device-pixel-ratio: 2/1),
      only screen and (min-device-pixel-ratio: 2),
      only screen and (min-resolution: 192dpi),
      only screen and (min-resolution: 2dppx) {
      background-image: url('../public/images/hero/rewards-xsx2.jpg');
      @include media-breakpoint-only(sm) {
        background-image: url('../public/images/hero/rewards-smx2.jpg');
      }
      @include media-breakpoint-only(md) {
        background-image: url('../public/images/hero/rewards-mdx2.jpg');
      }
      @include media-breakpoint-only(lg) {
        background-image: url('../public/images/hero/rewards-lgx2.jpg');
      }
      @include media-breakpoint-only(xl) {
        background-image: url('../public/images/hero/rewards-xlx2.jpg');
      }
      @include media-breakpoint-only(xxl) {
        background-image: url('../public/images/hero/rewards-xxlx2.jpg');
      }
    }
  }

  &.account-hero-container {
    background-image: url('../public/images/hero/account-xs.jpg');
    @include media-breakpoint-only(sm) {
      background-image: url('../public/images/hero/account-sm.jpg');
    }
    @include media-breakpoint-only(md) {
      background-image: url('../public/images/hero/account-md.jpg');
    }
    @include media-breakpoint-only(lg) {
      background-image: url('../public/images/hero/account-lg.jpg');
    }
    @include media-breakpoint-only(xl) {
      background-image: url('../public/images/hero/account-xl.jpg');
    }
    @include media-breakpoint-only(xxl) {
      background-image: url('../public/images/hero/account-xxl.jpg');
    }

    @media only screen and (-webkit-min-device-pixel-ratio: 2),
      only screen and (min--moz-device-pixel-ratio: 2),
      only screen and (-o-min-device-pixel-ratio: 2/1),
      only screen and (min-device-pixel-ratio: 2),
      only screen and (min-resolution: 192dpi),
      only screen and (min-resolution: 2dppx) {
      background-image: url('../public/images/hero/account-xsx2.jpg');
      @include media-breakpoint-only(sm) {
        background-image: url('../public/images/hero/account-smx2.jpg');
      }
      @include media-breakpoint-only(md) {
        background-image: url('../public/images/hero/account-mdx2.jpg');
      }
      @include media-breakpoint-only(lg) {
        background-image: url('../public/images/hero/account-lgx2.jpg');
      }
      @include media-breakpoint-only(xl) {
        background-image: url('../public/images/hero/account-xlx2.jpg');
      }
      @include media-breakpoint-only(xxl) {
        background-image: url('../public/images/hero/account-xxlx2.jpg');
      }
    }
  }

  &.support-hero-container {
    background-image: url('../public/images/hero/support-xs.jpg');
    @include media-breakpoint-only(sm) {
      background-image: url('../public/images/hero/support-sm.jpg');
    }
    @include media-breakpoint-only(md) {
      background-image: url('../public/images/hero/support-md.jpg');
    }
    @include media-breakpoint-only(lg) {
      background-image: url('../public/images/hero/support-lg.jpg');
    }
    @include media-breakpoint-only(xl) {
      background-image: url('../public/images/hero/support-xl.jpg');
    }
    @include media-breakpoint-only(xxl) {
      background-image: url('../public/images/hero/support-xxl.jpg');
    }

    @media only screen and (-webkit-min-device-pixel-ratio: 2),
      only screen and (min--moz-device-pixel-ratio: 2),
      only screen and (-o-min-device-pixel-ratio: 2/1),
      only screen and (min-device-pixel-ratio: 2),
      only screen and (min-resolution: 192dpi),
      only screen and (min-resolution: 2dppx) {
      background-image: url('../public/images/hero/support-xsx2.jpg');
      @include media-breakpoint-only(sm) {
        background-image: url('../public/images/hero/support-smx2.jpg');
      }
      @include media-breakpoint-only(md) {
        background-image: url('../public/images/hero/support-mdx2.jpg');
      }
      @include media-breakpoint-only(lg) {
        background-image: url('../public/images/hero/support-lgx2.jpg');
      }
      @include media-breakpoint-only(xl) {
        background-image: url('../public/images/hero/support-xlx2.jpg');
      }
      @include media-breakpoint-only(xxl) {
        background-image: url('../public/images/hero/support-xxlx2.jpg');
      }
    }
  }
}

.hero {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 56px 0 64px !important;
  text-align: center;
  @include media-breakpoint-up(md) {
    padding: 128px 0 144px !important;
  }
  @include media-breakpoint-up(lg) {
    padding: 192px 0 200px !important;
  }

  &.secondary-hero {
    padding: 64px 0 !important;
    @include media-breakpoint-up(md) {
      padding: 96px 0 112px !important;
    }
    @include media-breakpoint-up(lg) {
      padding: 112px 0 120px !important;
    }
  }

  .text-white-bold {
    font-weight: 700;
  }

  .hero-msg {
    flex-direction: column;
    margin-bottom: 32px;
    @include media-breakpoint-up(md) {
      max-width: 90%;
    }
    @include media-breakpoint-up(xl) {
      max-width: 80%;
    }

    h1,
    h2 {
      font-size: 40px;
      margin: 0;
      @include media-breakpoint-up(sm) {
        font-size: 48px;
      }
      @include media-breakpoint-up(lg) {
        font-size: 64px;
      }
    }

    h1 {
      margin-bottom: 4px;
      @include media-breakpoint-up(sm) {
        margin-bottom: 4px;
      }
    }

    h2 {
      margin-bottom: 16px;
      @include media-breakpoint-up(sm) {
        margin-bottom: 16px;
      }
    }

    p {
      margin: 0 auto 12px;
      width: 100%;
      @include media-breakpoint-up(sm) {
        font-size: 20px;
      }
    }

    .strap1 {
      max-width: 300px;
      @include media-breakpoint-up(sm) {
        max-width: 390px;
      }
      @include media-breakpoint-up(md) {
        max-width: 595px;
      }
      @include media-breakpoint-up(lg) {
        max-width: unset;
      }
    }

    .strap2 {
      max-width: 340px;
      @include media-breakpoint-up(sm) {
        max-width: 400px;
      }
      @include media-breakpoint-up(md) {
        max-width: 595px;
      }
      @include media-breakpoint-up(lg) {
        max-width: unset;
      }
    }
  }

  .hero-cta {
    margin-top: 32px !important;

    @include media-breakpoint-up(md) {
      > div {
        display: flex;

        &:first-child {
          justify-content: flex-end;
        }

        &:last-child {
          justify-content: flex-start;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      margin-top: 48px !important;
    }
  }
}

.callout {
  margin: 48px 0 56px;
  text-align: center;

  .title {
    font-size: 32px;
    margin-bottom: 16px;
    @include media-breakpoint-up(sm) {
      font-size: 48px;
    }
  }

  p {
    margin: 0 auto 12px;
    width: 100%;
    @include media-breakpoint-up(sm) {
      font-size: 20px;
    }
  }

  .strap1 {
    max-width: 265px;
    @include media-breakpoint-up(sm) {
      max-width: 300px;
    }
    @include media-breakpoint-up(lg) {
      max-width: unset;
    }
  }

  .strap2 {
    max-width: 340px;
    @include media-breakpoint-up(sm) {
      max-width: 450px;
    }

    @include media-breakpoint-up(lg) {
      max-width: unset;
    }
  }
}

.cards {
  margin-bottom: 64px;
}

.card {
  background-color: transparent;
  border: 0;
  margin: 0 auto 32px;
  text-align: center;
  width: 80%;
  @include media-breakpoint-up(md) {
    width: 100%;
  }

  .card-img-top {
    height: 72px;
  }

  .card-body {
    padding: 32px 0 16px;

    .card-title {
      font-size: 24px;
      margin-bottom: 16px;

      span {
        display: block;
      }
    }

    .card-text {
      span {
        display: block;
      }
    }
  }
}

.social-cards {
  .col-12 {
    margin: 0 20px 32px;
  }

  .card {
    border: 1px solid $box-border;
    display: flex;
    height: 100%;
    justify-content: space-between;
    margin: 0;
    padding: 32px;
    width: 100%;

    .card-header,
    .card-body,
    .card-footer {
      padding: 0;
    }
    
    .card-header {
      margin-bottom: 32px;
    }
    
    .card-text {
      margin: 0;
    }
    
    img {
      height: 32px;
    }
    
    .title1 {
      font-size: 24px;
      font-weight: $font-bold;
      margin-bottom: 4px;
      @include media-breakpoint-up(sm) {
        font-size: 32px;
      }
    }
    
    .title2 {
      font-size: 16px;
      font-weight: $font-bold;
      margin-bottom: 32px;
      @include media-breakpoint-up(sm) {
        font-size: 20px;
      }
    }

    .message {
      margin-bottom: 32px;
    }

    .btn {
      max-width: 288px;
      min-width: unset !important;
      width: 100%;

      &:hover {
        background-color: $primary;
        border-color: $primary;
      }
    }
  }
}

.banner {
  margin-bottom: 80px;

  .bg {
    background: linear-gradient(135deg, #03bfb5 0%, #28b2de 100%);
    border-radius: 8px;
    padding: 32px 16px;
    text-align: center;
  }

  img {
    height: 48px;
    margin-bottom: 16px;
    @include media-breakpoint-up(md) {
      height: 72px;
    }
  }

  .title {
    font-size: 40px;
    margin-bottom: 16px;
    @include media-breakpoint-up(md) {
      font-size: 56px;
    }
  }

  .message {
    margin: 0 0 24px;
    @include media-breakpoint-up(md) {
      font-size: 20px;
      margin: 0 0 32px;
    }

    .first-break {
      @include media-breakpoint-up(sm) {
        display: block;
      }

      &:first-child {
        margin-right: 4px;
      }

      .second-break {
        @include media-breakpoint-only(sm) {
          display: block;
        }
        @include media-breakpoint-only(md) {
          display: block;
        }
        @include media-breakpoint-only(lg) {
          display: block;
        }

        &:first-child {
          margin-right: 4px;
        }
      }
    }
  }

  .btn-outline-primary {
    background-color: transparent !important;
    @include media-breakpoint-only(xs) {
      min-width: unset;
      width: 100% !important;
    }
  }
}

.account-container {
  display: flex;
  flex-direction: row;
  margin: 40px 0;

  @include media-breakpoint-up(sm) {
    margin: 48px 0;
  }

  .col-lg-6 {
    display: flex;
  }

  .col {
    .edit-panel {
      margin: 0;
      max-width: unset;
    }
  }

  .edit-panel {
    background-color: $box-bg;
    border: 1px solid $box-border;
    border-radius: 8px;
    margin: 0 0 32px;
    padding: 32px;
    width: 100%;

    &.personal-details {
      padding: 32px 32px 32px 16px;
    }

    &.password-reset {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .btn-danger {
        margin-right: 16px;
      }

      .password-inputs {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .form-field-text {
          margin-bottom: 32px;
        }
      }

      .error-messages {
        border: 1px solid $error;
        border-radius: 8px;
        color: $white;
        display: flex;
        font-size: 16px;
        justify-content: flex-start;
        margin-bottom: 32px;
        padding: 16px;

        p {
          margin: 0;
        }
      }
    }

    &.password-management {
      .message {
        p {
          margin-bottom: 16px;
          text-align: center;
        }
      }
    }

    &.password-management,
    &.reveller-management,
    &.wallet-management {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &.danger-zone {
      .message {
        margin-bottom: 32px;
      }

      p {
        text-align: center;
      }
    }

    .no-data {
      margin-bottom: 32px;
      text-align: center;
    }

    .wallets {
      align-items: stretch;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin-bottom: 32px;
    }

    .wallet {
      border-top: 1px solid $box-border;
      display: flex;
      flex-direction: row;
      padding: 16px 0;

      &:last-child {
        border-bottom: 1px solid $box-border;
      }

      img {
        height: 20px;
        margin-left: 12px;
      }

      .icon-default {
        margin: 0;
      }

      .default {
        margin-left: 8px;
      }
    }

    .revellers {
      align-items: stretch;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin-bottom: 32px;
    }

    .reveller {
      border-top: 1px solid $box-border;
      display: flex;
      flex-direction: row;
      padding: 16px 0;

      &:last-child {
        border-bottom: 1px solid $box-border;
      }
    }
  }

  h1 {
    font-size: 24px;
    margin: 0 0 40px;
    text-align: center;
  }

  .input-edit-container {
    display: flex;
    margin-bottom: 32px;

    &:last-child {
      margin: 0;
    }
  }

  .input-edit {
    flex-grow: 1;

    .input-label {
      background-color: $box-bg;
    }
  }

  .input-edit-icon {
    align-items: center;
    display: flex;
    flex-grow: 0;
    margin-left: 16px;

    .icon {
      height: 20px;
    }
  }

  .buttons {
    text-align: center;

    .btn-primary {
      @include media-breakpoint-only(xs) {
        min-width: unset;
      }
    }
  }

  .hide-border {
    border-color: transparent;
  }
}

.help-container {
  h1 {
    margin: 0 0 24px;
    text-align: center;
  }

  p {
    margin: 0;
    text-align: center;
  }

  .help-form {
    margin-top: 48px;

    .form-field-text {
      margin-bottom: 32px;
    }

    .btn {
      width: 100%;
      @include media-breakpoint-up(sm) {
        max-width: 288px;
      }
    }
  }
}

.social-banner {
  h1 {
    font-weight: $font-bold;
    margin: 0 0 24px;
    text-align: center;
  }

  p {
    margin: 0 0 64px;
    text-align: center;
  }
}
// Alerts
.alert {
  position: sticky;
}

// Wallet selector
.wallet-selector-container {
  border-bottom: 1px solid $secondary-bg-border;
  padding: 40px 0;
  @include media-breakpoint-up(sm) {
    padding: 48px 0;
  }
}

.live-balance-container {
  padding: 40px 0;
  @include media-breakpoint-up(sm) {
    padding: 48px 0;
  }
}

.faq-container {
  border-bottom: 1px solid $secondary-bg-border;
  padding: 40px 0;
  @include media-breakpoint-up(sm) {
    padding: 48px 0;
  }
}

.social-container {
  border-bottom: 1px solid $secondary-bg-border;
  padding: 40px 0;
  @include media-breakpoint-up(sm) {
    padding: 48px 0;
  }
}

.help-container {
  padding: 40px 0 48px;
  @include media-breakpoint-up(sm) {
    padding: 48px 0 80px;
  }
}

.notifications-container {
  padding: 0;
}

.table-widget-container {
  border-top: 1px solid $secondary-bg-border;
  padding: 40px 0;
  @include media-breakpoint-up(sm) {
    padding: 48px 0;
  }
}

.fair-notice-container {
  border-top: 1px solid $secondary-bg-border;
  padding: 40px 0;
  @include media-breakpoint-up(sm) {
    padding: 48px 0;
  }

  .col {
    display: flex;
    justify-content: center;
  }
}

.kicked {
  border-bottom: 1px solid $secondary-bg-border !important;
  border-top: 0 !important;
}

.rewards-summary-container {
  padding: 40px 0;
  @include media-breakpoint-up(sm) {
    padding: 48px 0;
  }
}

.reward-create-plan-container {
  padding: 40px 0;
  @include media-breakpoint-up(sm) {
    padding: 48px 0;
  }
}

.wallet-selector {
  text-align: center;

  img {
    height: 40px;
  }

  .info-text {
    padding: 32px;

    h1 {
      font-size: 28px;
    }

    p {
      margin: 0;
    }
  }

  select {
    margin: 0 auto;
    width: auto;
  }
}

.live-balance {
  background-color: $secondary-bg;
  border: 1px solid $white;
  border-radius: 8px;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  @include media-breakpoint-up(md) {
    width: 560px;
  }

  .increase-icon {
    padding: 24px 0 16px;
    @include media-breakpoint-up(lg) {
      padding: 32px 0 24px;
    }

    img {
      height: 40px;
      @include media-breakpoint-up(sm) {
        height: 48px;
      }
    }
  }

  .balance-text {
    font-size: 32px;
    padding: 0;
    @include media-breakpoint-up(sm) {
      font-size: 40px;
    }
  }

  .snapshot-time {
    color: #bebebe;
    font-size: 14px;
    @include media-breakpoint-up(sm) {
      font-size: 16px;
    }
  }

  .balance {
    color: $primary;
    font-size: 28px;
    font-weight: 700;
    padding: 24px 0 0;
    @include media-breakpoint-up(sm) {
      font-size: 32px;
      padding: 28px 0 0;
    }

    .text-small {
      font-size: 16px;

      @include media-breakpoint-up(lg) {
        font-size: 20px;
      }
    }
  }

  .balance-change {
    color: $white;
    font-size: 16px;
    font-weight: $font-semi;
    padding: 8px 0 24px;
    @include media-breakpoint-up(sm) {
      font-size: 20px;
      padding: 16 0 32px;
    }
  }
}

.notifications {
  .col-12 {
    padding: 0 0 40px;
  }
}

.notification {
  margin: 0 0 48px;
  text-align: center;

  &:last-child {
    margin: 0;
  }

  .icon {
    margin: 0 0 16px;

    img {
      height: 32px;
      @include media-breakpoint-up(sm) {
        height: 40px;
      }
      @include media-breakpoint-up(lg) {
        height: 48px;
      }
    }
  }

  .title {
    font-size: 20px;
    margin: 0 0 8px;
  }

  .value {
    color: $white;
    font-size: 20px;
    font-weight: $font-bold;
    text-transform: uppercase;
  }
}

.table-widget {
  > .row {
    > div {
      &:last-child {
        margin-top: 40px;
        @include media-breakpoint-up(lg) {
          margin-top: 0;
        }
      }
    }
  }

  h3 {
    font-size: 32px;
    margin-bottom: 16px;
    text-align: center;
  }

  p {
    margin-bottom: 32px;
    text-align: center;
  }
}

.table-head {
  background-color: $secondary-bg;
  border-bottom: 0;
  border-left: 1px solid $secondary-bg-border;
  border-radius: 8px 8px 0 0;
  border-right: 1px solid $secondary-bg-border;
  border-top: 1px solid $secondary-bg-border;
  width: 100%;

  .row {
    padding: 24px 0;
    text-align: center;
  }

  span {
    display: block;
    line-height: 1;
    margin-top: 16px;
    text-align: center;
  }

  img {
    height: 24px;
  }
}

.table-data {
  background-color: $secondary-bg;
  border: 1px solid $secondary-bg-border;

  border-radius: 0 0 8px 8px;
  width: 100%;

  .row {
    padding: 8px 0;
    text-align: center;

    > div {
      line-height: 1;
      padding-bottom: 8px;
      padding-top: 8px;
    }

    &:first-child {
      padding-top: 16px;
    }

    &:last-child {
      padding-bottom: 16px;
    }
  }
}

.fair-notice {
  background-color: $secondary-bg;
  border: 1px solid $secondary-bg-warning;
  border-radius: 8px;
  display: flex;
  max-width: 560px;

  &.kicked {
    border: 1px solid $red !important;
  }

  > div {
    padding: 16px;
  }

  p {
    margin: 0;
  }

  .title {
    font-weight: 600;
    line-height: 32px;
  }

  .message {
    line-height: 1.5;
  }

  img {
    height: 32px;
  }
}

.revel-container {
  text-align: center;

  img {
    margin: 32px;
    width: 220px;
    @include media-breakpoint-up(md) {
      width: 340px;
    }
    @include media-breakpoint-up(lg) {
      width: 446px;
    }
  }

  h1 {
    font-size: 56px;
    margin: 32px 0 0;

    @include media-breakpoint-up(md) {
      font-size: 64px;
    }

    span {
      display: block;
      font-weight: 800;
      line-height: 1.4;
      margin-bottom: 8px;
      @include media-breakpoint-up(md) {
        display: inline;
      }
    }
  }

  .supporting-text {
    margin: 32px 0;
  }

  .btn-connect {
    padding: 16px 0 80px;
  }
}

.plan-text-container {
  display: flex;
  justify-content: center;
  @include media-breakpoint-up(lg) {
    justify-content: flex-start;
  }
}

.plan-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 32px;
  text-align: center;

  @include media-breakpoint-up(sm) {
    margin-bottom: 40px;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
    text-align: left;
  }

  p {
    @include media-breakpoint-up(xl) {
      max-width: 440px;
    }
  }

  h3 {
    font-size: 32px;
    margin-bottom: 8px;

    @include media-breakpoint-up(lg) {
      font-size: 48px;
    }

    span {
      display: block;
    }
  }

  p {
    margin: 0;
  }
}

.plan-summary {
  background-color: $secondary-bg;
  border: 1px solid $secondary-bg-border;
  border-radius: 8px;
  padding: 24px;
  text-align: center;

  // Fudge
  .locked-bpm {
    margin-left: 6px;
  }

  .col-6 {
    padding-bottom: 32px;
  }

  .progress-container {
    padding: 0 0 16px;

    .progress {
      height: 8px !important;
    }

    .increase-to-date {
      color: $primary;
      font-weight: $font-semi;
    }

    .increase-to-date,
    .days-to-go {
      margin-top: 8px;
      text-align: left;
    }

    .days-to-go {
      text-align: right;

      span {
        font-weight: $font-semi;
      }
    }
  }

  .action-buttons {
    padding: 32px 0 0;

    .col {
      display: flex;
      flex-direction: column;
      @include media-breakpoint-up(sm) {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    .btn {
      background-color: transparent;
      border: 0 !important;
      color: $white;
      display: flex;
      margin: 0;
      min-width: unset;
      padding: 12px 0 !important;
      text-align: left;
      @include media-breakpoint-up(sm) {
        margin-bottom: 0;
        padding: 0 !important;
      }
    }

    img {
      height: 20px;
      margin-right: 8px;
    }

    .auto-renew-state {
      font-weight: 200;
    }
  }

  .missed-opportunity {
    border: 1px solid $secondary-bg-warning;
    border-radius: 8px;
    margin: 0;
    padding: 16px;
  }
}

.summary-title {
  font-weight: $font-semi;
  margin-bottom: 32px;
}

.summary-icons {
  .icon {
    margin-bottom: 8px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 12px;
    }

    img {
      height: 24px;
    }
  }

  .title {
    font-weight: $font-medium;
  }

  .value {
    color: $primary;
    font-size: 16px;
    font-weight: $font-semi;
    margin-top: 12px;
  }

  .spinner {
    height: 32px;
    margin-top: 20px;
    width: 32px;

    &::after {
      height: 32px;
      width: 32px;
    }
  }
}

.reward-create-plan {
  .plan-text {
    h3 {
      span {
        display: block;
      }
    }
  }

  .tabs-container {
    @include media-breakpoint-up(lg) {
      margin: 32px 0;
    }

    .nav-link {
      img {
        height: 32px;
      }
    }
  }
}

.reward-builder {
  align-items: center;
  background-color: $box-bg;
  border: 1px solid $box-border;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 16px;

  .builder {
    text-align: center;
    width: 100%;

    img {
      height: 40px;
    }
  }

  .builder1 {
    font-weight: 300;
    width: 75%;

    .title {
      font-weight: 600;
      margin: 16px 0 32px;
    }

    ul {
      list-style-position: outside;
      margin-bottom: 32px;
      padding: 0;
      text-align: left;
    }

    li {
      margin-bottom: 8px;
    }

    .form-check {
      margin: 0 0 32px;
      text-align: left;

      .form-check-input {
        border-color: $white;
      }
    }

    .btn-primary {
      @include media-breakpoint-only(xs) {
        max-width: 288px;
        min-width: unset;
        width: 100%;
      }
    }
  }

  .builder2 {
    // width: 75%;

    .title {
      font-weight: 600;
      margin: 16px 0 32px;
    }

    .question {
      margin: 16px 0 32px;
    }

    .rc-slider-container {
      padding: 0 16px 0 4px;
    }

    .plan-amount-percentage {
      font-size: 24px;
      font-weight: 600;
      margin: 16px 0 0;
    }

    .of-holdings {
      font-size: 16px;
      font-weight: 300;
    }

    .plan-amount {
      color: $primary;
      font-size: 20px;
      font-weight: 600;
      margin: 16px 0 48px;
    }

    .currency {
      font-size: 16px;
      font-weight: 600;
      margin-left: 6px;
    }

    .period-selector-container {
      margin: 0 0 32px;
    }

    .period-selector {
      font-size: 20px;
      margin: 0 auto;
      padding: 8px 64px;
      width: auto;
    }
  }

  .builder3 {
    .title {
      font-weight: 600;
      margin: 16px 0 0;
    }

    .summary-icons {
      > div {
        margin-top: 32px;
        padding: 0;
      }
    }

    .projected-rewards {
      font-weight: 600;
      margin-bottom: 16px;
      margin-top: 48px;
    }

    .icon {
      margin: 8px 0;

      img {
        height: 32px;
      }
    }

    .projected-earnings {
      color: $primary;
      font-size: 20px;
      font-weight: 600;
    }

    .projected-percentage {
      font-size: 20px;
      font-weight: 600;
    }

    .action-buttons {
      margin-top: 48px;

      .btn {
        min-width: unset;
        padding: 16px;
        width: 100% !important;
      }

      .btn-outline-primary {
        margin-bottom: 24px;
        @include media-breakpoint-up(sm) {
          margin-bottom: 0;
        }
      }
    }
  }

  .ineligible {
    .icon {
      margin: 8px 0;
      text-align: center;

      img {
        height: 32px;
      }
    }

    .title {
      font-size: 20px;
      font-weight: $font-semi;
      margin: 16px 0;
      text-align: center;
    }

    .supporting-text {
      text-align: center;
    }

    .action-buttons {
      margin: 16px 0;
      text-align: center;
    }
  }
}

//Faqs
.faq-container {
  .accordion-item {
    background-color: transparent;
    border: 0;

    &:first-of-type {
      .accordion-body {
        box-shadow: none;
      }
    }
  }

  .accordion-header {
    padding: 16px 0;
  }

  .accordion-body {
    border: 0;
    padding: 0 0 32px;
  }

  .accordion-button {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    padding: 0;

    &::after {
      background-image: url("data:image/svg+xml,%3Csvg width='72' height='72' viewBox='0 0 72 72' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M50.7692 31.0769L36 45.8462L21.2308 31.0769M36 68C18.3269 68 4 53.6731 4 36C4 18.3269 18.3269 4 36 4C53.6731 4 68 18.3269 68 36C68 53.6731 53.6731 68 36 68Z' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
    }

    &:not(.collapsed) {
      &::after {
        background-image: url("data:image/svg+xml,%3Csvg width='72' height='72' viewBox='0 0 72 72' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M50.7692 31.0769L36 45.8462L21.2308 31.0769M36 68C18.3269 68 4 53.6731 4 36C4 18.3269 18.3269 4 36 4C53.6731 4 68 18.3269 68 36C68 53.6731 53.6731 68 36 68Z' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
      }
    }

    &:focus {
      box-shadow: none;
    }
  }
}

// Toasts
.toast {
  .toast-header {
    background-color: rgba(255, 255, 255, 0);

    .btn-close {
      height: 2px;
      width: 2px;
    }
  }

  .title {
    color: $white;
  }

  img {
    height: 24px;
    margin-right: 8px;
  }

  .toast-body {
    background-color: rgba(255, 255, 255, 0.3);
  }

  &.bg-warning {
    background-color: $bronze !important;
  }
}

// rc-slider
.rc-slider {
  border-radius: 8px;
  box-sizing: border-box;
  height: 16px;
  padding: 6px 0;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  touch-action: none;
  width: 100%;
}

.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-rail {
  background-color: $white;
  border-radius: 6px;
  height: 6px;
  position: absolute;
  width: 100%;
}

.rc-slider-track {
  background-color: $primary;
  border-radius: 6px;
  height: 6px;
  position: absolute;
}

.rc-slider-handle {
  background-color: $primary;
  border: solid 2px $primary-dark;
  border-radius: 25%;
  cursor: pointer;
  cursor: grab;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  cursor: -webkit-grab;
  height: 16px;
  margin-top: -5px;
  opacity: 0.8;
  position: absolute;
  touch-action: pan-x;
  width: 20px;
}

.rc-slider-handle:focus {
  box-shadow: none;
  outline: none;
}

.rc-slider-handle:focus-visible {
  border-color: $primary-dark;
  box-shadow: 0 0 0 1px $primary;
}

.rc-slider-handle-click-focused:focus {
  border-color: $primary-dark;
  box-shadow: unset;
}

.rc-slider-handle:hover {
  border-color: $primary-dark;
}

.rc-slider-handle:active {
  border-color: $primary-dark;
  box-shadow: 0 0 1px $primary-dark;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.rc-slider-mark {
  font-size: 12px;
  left: 0;
  position: absolute;
  top: 18px;
  width: 100%;
}

.rc-slider-mark-text {
  color: #999;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  text-align: center;
  vertical-align: middle;
}

.rc-slider-mark-text-active {
  color: #666;
}

.rc-slider-step {
  background: transparent;
  height: 4px;
  pointer-events: none;
  position: absolute;
  width: 100%;
}

.rc-slider-dot {
  background-color: #fff;
  border: 2px solid #e9e9e9;
  border-radius: 50%;
  bottom: -2px;
  cursor: pointer;
  height: 8px;
  position: absolute;
  vertical-align: middle;
  width: 8px;
}

.rc-slider-dot-active {
  border-color: #96dbfa;
}

.rc-slider-dot-reverse {
  margin-right: -4px;
}

.rc-slider-disabled {
  background-color: #e9e9e9;
}

.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}

.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  background-color: #fff;
  border-color: #ccc;
  box-shadow: none;
  cursor: not-allowed;
}

.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important;
}

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
  display: block !important;
}

.rc-slider-tooltip-zoom-down-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
  display: block !important;
}

.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  animation-name: rcSliderTooltipZoomDownIn;
  animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  animation-name: rcSliderTooltipZoomDownOut;
  animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  transform: scale(0, 0);
}

.rc-slider-tooltip-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform: scale(0, 0);
    transform-origin: 50% 100%;
  }

  100% {
    transform: scale(1, 1);
    transform-origin: 50% 100%;
  }
}
@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform: scale(1, 1);
    transform-origin: 50% 100%;
  }

  100% {
    opacity: 0;
    transform: scale(0, 0);
    transform-origin: 50% 100%;
  }
}

.maintenance-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: $black;
  color: #343a40;
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.maintenance-container h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color:$white;
}

.maintenance-container p {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color:$white;
}
.maintenance-container a {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color:$primary;
}
.maintenance-icon img {
  max-width: 100px;
  margin-bottom: 10px;
}
